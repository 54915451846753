import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-logout-icons',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './logout-icon.component.html',
    styleUrls: [],
})
export class LogoutIconsComponent {}
