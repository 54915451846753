<div
    [ngClass]="{
        'border-twSuccessGreen bg-twLightGreen': toastPackage.toastType === 'toast-success',
        'border-twElectricPurple bg-twLightPurple': toastPackage.toastType === 'toast-info',
        'border-twClassicOrange bg-twLightOrange': toastPackage.toastType === 'toast-warning',
        'border-twErrorRed bg-twBgErrorRed': toastPackage.toastType === 'toast-error',
        'bg-twMainBackground': !toastPackage.toastType
    }"
    class="relative inset-0 h-full w-full overflow-hidden rounded-card border p-[18px] shadow-toastr transition-all hover:shadow-toastrHover">
    <div class="row flex items-center gap-4">
        <!-- ICON -->
        <app-square-checkmark
            *ngIf="toastPackage.toastType === 'toast-success'"
            class="text-twSuccessGreen" />
        <app-warning-hex
            *ngIf="toastPackage.toastType === 'toast-info'"
            class="rotate-180 text-twElectricPurple" />
        <app-warning-hex
            *ngIf="toastPackage.toastType === 'toast-warning'"
            class="text-twClassicOrange" />
        <app-cross-circle-inverse
            *ngIf="toastPackage.toastType === 'toast-error'"
            class="text-twErrorRed" />
        <!-- TITLE -->
        <div class="col-9">
            <h3 [class]="'text font-semibold text-twTextPrimary'" [attr.aria-label]="title">
                {{ getTitle() }}
            </h3>
            <!-- TEXT -->
            <p
                *ngIf="message && options.enableHtml"
                role="alert"
                [class]="'text-xs text-twGrayDark'"
                [innerHTML]="message"></p>
            <p
                *ngIf="message && !options.enableHtml"
                role="alert"
                [class]="'text-xs text-twGrayDark'"
                [attr.aria-label]="message">
                {{ message }}
            </p>
        </div>
    </div>

    <div *ngIf="options.progressBar">
        <div class="toast-progress" [style.width]="width + '%'"></div>
    </div>
</div>
