import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-gear-icon',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './gear-icon.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GearIconComponent {}
