import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { CrossIconComponent } from '../icons/cross-icon/cross-icon.component';
import { SquareCheckmarkComponent } from '../icons/square-checkmark/square-checkmark.component';
import { WarningHexComponent } from '../icons/warning-hex/warning-hex.component';
import { CrossCircleInverseComponent } from '../icons/cross-circle-inverse/cross-circle-inverse.component';

@Component({
    selector: '[app-customized-ngx-toast]',
    imports: [
        CommonModule,
        CrossIconComponent,
        SquareCheckmarkComponent,
        WarningHexComponent,
        CrossCircleInverseComponent,
    ],
    standalone: true,
    styles: [
        `
            :host {
                position: relative;
                max-width: 400px;
                background: transparent;
                pointer-events: all;
                cursor: pointer;
                background: none;
            }
            .btn-pink {
                -webkit-backface-visibility: hidden;
                -webkit-transform: translateZ(0);
            }
        `,
    ],
    templateUrl: './customized-ngx-toast.component.html',
    animations: [
        trigger('flyInOut', [
            state(
                'inactive',
                style({
                    opacity: 0,
                })
            ),
            transition(
                'inactive => active',
                animate(
                    '400ms ease-out',
                    keyframes([
                        style({
                            transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
                            opacity: 0,
                        }),
                        style({
                            transform: 'skewX(20deg)',
                            opacity: 1,
                        }),
                        style({
                            transform: 'skewX(-5deg)',
                            opacity: 1,
                        }),
                        style({
                            transform: 'none',
                            opacity: 1,
                        }),
                    ])
                )
            ),
            transition(
                'active => removed',
                animate(
                    '400ms ease-out',
                    keyframes([
                        style({
                            opacity: 1,
                        }),
                        style({
                            transform: 'translate3d(100%, 0, 0) skewX(30deg)',
                            opacity: 0,
                        }),
                    ])
                )
            ),
        ]),
    ],
    preserveWhitespaces: false,
})
export class CustomizedNgxToastComponent extends Toast {
    // used for demo purposes
    undoString = 'undo';

    // constructor is only necessary when not using AoT
    constructor(
        @Inject(ToastrService) public override toastrService: ToastrService,
        @Inject(ToastPackage) public override toastPackage: ToastPackage
    ) {
        super(toastrService, toastPackage);
    }

    action(event: Event) {
        event.stopPropagation();
        this.undoString = 'undid';
        this.toastPackage.triggerAction();
        return false;
    }

    getTitle() {
        if (this.toastPackage.title) return this.toastPackage.title;
        if (this.toastPackage.toastType === 'toast-error') return 'Something went wrong!';
        return '';
    }
}
