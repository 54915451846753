<svg width="50" height="30" viewBox="0 0 50 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="5.5" width="19" height="19" rx="9.5" fill="#A216E4" />
    <rect x="0.5" y="5.5" width="19" height="19" rx="9.5" fill="black" fill-opacity="0.2" />
    <mask
        id="mask0_4060_19977"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="6"
        y="11"
        width="8"
        height="8">
        <mask
            id="mask1_4060_19977"
            style="mask-type: luminance"
            maskUnits="userSpaceOnUse"
            x="6"
            y="11"
            width="8"
            height="8">
            <path d="M13.9304 11.6309H6.06934V18.3689H13.9304V11.6309Z" fill="currentColor" />
        </mask>
        <g mask="url(#mask1_4060_19977)">
            <path
                d="M9.5687 11.6309H7.47834C7.44468 11.6308 7.41172 11.6406 7.38338 11.659C7.35504 11.6773 7.33248 11.7036 7.3184 11.7345L6.0853 14.4263C6.07304 14.4536 6.0677 14.4836 6.06977 14.5134C6.07184 14.5433 6.08124 14.5722 6.09713 14.5975C6.11302 14.6228 6.13489 14.6436 6.16075 14.6581C6.18662 14.6727 6.21566 14.6804 6.24524 14.6807H7.54232C7.58918 14.6807 7.63414 14.6996 7.66728 14.7331C7.70042 14.7667 7.71904 14.8122 7.71904 14.8597V18.1899C7.71904 18.2373 7.73766 18.2829 7.7708 18.3165C7.80396 18.35 7.8489 18.3689 7.89578 18.3689H9.5679C9.61478 18.3689 9.65972 18.35 9.69286 18.3165C9.726 18.2829 9.74464 18.2373 9.74464 18.1899V11.8099C9.74464 11.7625 9.72612 11.7171 9.69314 11.6836C9.66018 11.65 9.61544 11.6311 9.5687 11.6309Z"
                fill="#BD33ED" />
            <path
                d="M13.9138 14.4269L12.6819 11.7346C12.6676 11.7037 12.6451 11.6776 12.6168 11.6592C12.5885 11.6409 12.5556 11.631 12.522 11.6309H10.4315C10.3847 11.6309 10.3397 11.6497 10.3066 11.6833C10.2735 11.7169 10.2549 11.7624 10.2549 11.8099V18.1898C10.2549 18.2373 10.2735 18.2829 10.3066 18.3164C10.3397 18.35 10.3847 18.3689 10.4315 18.3689H12.1039C12.1508 18.3689 12.1957 18.35 12.2288 18.3164C12.262 18.2829 12.2806 18.2373 12.2806 18.1898V14.8604C12.2806 14.8129 12.2992 14.7674 12.3323 14.7338C12.3654 14.7002 12.4104 14.6813 12.4572 14.6813H13.7531C13.7828 14.6814 13.812 14.6739 13.8381 14.6595C13.8642 14.645 13.8863 14.6242 13.9023 14.5988C13.9183 14.5735 13.9278 14.5444 13.9298 14.5144C13.9318 14.4843 13.9263 14.4542 13.9138 14.4269Z"
                fill="#BD33ED" />
        </g>
    </mask>
    <g mask="url(#mask0_4060_19977)">
        <path d="M13.9304 11.6309H6.06934V18.3689H13.9304V11.6309Z" fill="currentColor" />
    </g>
    <rect x="0.5" y="5.5" width="19" height="19" rx="9.5" stroke="currentColor" />
    <rect x="30.5" y="5.5" width="19" height="19" rx="9.5" fill="#A216E4" />
    <rect x="30.5" y="5.5" width="19" height="19" rx="9.5" fill="black" fill-opacity="0.2" />
    <mask
        id="mask2_4060_19977"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="36"
        y="11"
        width="8"
        height="8">
        <mask
            id="mask3_4060_19977"
            style="mask-type: luminance"
            maskUnits="userSpaceOnUse"
            x="36"
            y="11"
            width="8"
            height="8">
            <path d="M43.9304 11.6309H36.0693V18.3689H43.9304V11.6309Z" fill="currentColor" />
        </mask>
        <g mask="url(#mask3_4060_19977)">
            <path
                d="M39.5687 11.6309H37.4783C37.4447 11.6308 37.4117 11.6406 37.3834 11.659C37.355 11.6773 37.3325 11.7036 37.3184 11.7345L36.0853 14.4263C36.073 14.4536 36.0677 14.4836 36.0698 14.5134C36.0718 14.5433 36.0812 14.5722 36.0971 14.5975C36.113 14.6228 36.1349 14.6436 36.1608 14.6581C36.1866 14.6727 36.2157 14.6804 36.2452 14.6807H37.5423C37.5892 14.6807 37.6341 14.6996 37.6673 14.7331C37.7004 14.7667 37.719 14.8122 37.719 14.8597V18.1899C37.719 18.2373 37.7377 18.2829 37.7708 18.3165C37.804 18.35 37.8489 18.3689 37.8958 18.3689H39.5679C39.6148 18.3689 39.6597 18.35 39.6929 18.3165C39.726 18.2829 39.7446 18.2373 39.7446 18.1899V11.8099C39.7446 11.7625 39.7261 11.7171 39.6931 11.6836C39.6602 11.65 39.6154 11.6311 39.5687 11.6309Z"
                fill="#BD33ED" />
            <path
                d="M43.9138 14.4269L42.6819 11.7346C42.6676 11.7037 42.6451 11.6776 42.6168 11.6592C42.5885 11.6409 42.5556 11.631 42.522 11.6309H40.4315C40.3847 11.6309 40.3397 11.6497 40.3066 11.6833C40.2735 11.7169 40.2549 11.7624 40.2549 11.8099V18.1898C40.2549 18.2373 40.2735 18.2829 40.3066 18.3164C40.3397 18.35 40.3847 18.3689 40.4315 18.3689H42.1039C42.1508 18.3689 42.1957 18.35 42.2288 18.3164C42.262 18.2829 42.2806 18.2373 42.2806 18.1898V14.8604C42.2806 14.8129 42.2992 14.7674 42.3323 14.7338C42.3654 14.7002 42.4104 14.6813 42.4572 14.6813H43.7531C43.7828 14.6814 43.812 14.6739 43.8381 14.6595C43.8642 14.645 43.8863 14.6242 43.9023 14.5988C43.9183 14.5735 43.9278 14.5444 43.9298 14.5144C43.9318 14.4843 43.9263 14.4542 43.9138 14.4269Z"
                fill="#BD33ED" />
        </g>
    </mask>
    <g mask="url(#mask2_4060_19977)">
        <path d="M43.9304 11.6309H36.0693V18.3689H43.9304V11.6309Z" fill="#A216E4" />
        <path
            d="M43.9304 11.6309H36.0693V18.3689H43.9304V11.6309Z"
            fill="black"
            fill-opacity="0.2" />
    </g>
    <rect x="30.5" y="5.5" width="19" height="19" rx="9.5" stroke="currentColor" />
    <rect x="10.5" y="0.5" width="29" height="29" rx="14.5" fill="#A216E4" />
    <rect x="10.5" y="0.5" width="29" height="29" rx="14.5" fill="black" fill-opacity="0.2" />
    <g clip-path="url(#clip0_4060_19977)">
        <path
            d="M31.6 15.1562C31.6 14.6688 31.5563 14.2 31.475 13.75H25V16.4094H28.7C28.5406 17.2688 28.0563 17.9969 27.3281 18.4844V20.2094H29.55C30.85 19.0125 31.6 17.25 31.6 15.1562Z"
            fill="currentColor" />
        <path
            d="M24.9997 21.8734C26.856 21.8734 28.4122 21.2578 29.5497 20.2078L27.3278 18.4828C26.7122 18.8953 25.9247 19.1391 24.9997 19.1391C23.2091 19.1391 21.6935 17.9297 21.1528 16.3047H18.856V18.0859C19.9872 20.3328 22.3122 21.8734 24.9997 21.8734Z"
            fill="currentColor" />
        <path
            d="M21.1531 16.3078C21.0156 15.8953 20.9375 15.4547 20.9375 15.0016C20.9375 14.5484 21.0156 14.1078 21.1531 13.6953V11.9141H18.8563C18.375 12.8721 18.1246 13.9294 18.125 15.0016C18.125 16.1109 18.3906 17.1609 18.8563 18.0891L21.1531 16.3078Z"
            fill="currentColor" />
        <path
            d="M24.9997 10.8594C26.0091 10.8594 26.9153 11.2063 27.6278 11.8875L29.5997 9.91563C28.4091 8.80625 26.8528 8.125 24.9997 8.125C22.3122 8.125 19.9872 9.66563 18.856 11.9125L21.1528 13.6938C21.6935 12.0688 23.2091 10.8594 24.9997 10.8594Z"
            fill="currentColor" />
    </g>
    <rect x="10.5" y="0.5" width="29" height="29" rx="14.5" stroke="currentColor" />
    <defs>
        <clipPath id="clip0_4060_19977">
            <rect width="15" height="15" fill="currentColor" transform="translate(17.5 7.5)" />
        </clipPath>
    </defs>
</svg>
