import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-analytics-icon',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './analytics-icon.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsIconComponent {}
