import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-sync-ecommerce-icon',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './sync-ecommerce-icon.component.html',
    styles: [],
})
export class SyncEcommerceIconComponent {}
