<div
    class="flex h-10 flex-grow items-center justify-center gap-4 rounded-b border border-twClassicOrange bg-twLightOrange px-3 text-[13px]">
    <app-warning-hex class="h-5 w-5 text-twClassicOrange" />

    <div class="font-light">{{ content?.text }}</div>

    <button
        class="rounded-card bg-twClassicOrange px-3 py-1 font-semibold text-twTextInverse"
        (click)="handleClick()">
        {{ content?.btnText }}
    </button>
</div>
