<div
    [ngClass]="{
        'bg-twMainBackground text-twTextPrimary': active && !disabled,
        'border-twLightPurple': !disabled,
        'border-twMidDarkPurple bg-transparent text-twMidDarkPurple': disabled
    }"
    class="flex w-[303px] items-center gap-4 whitespace-nowrap rounded-badge border-[2px] p-5">
    <div
        [ngClass]="{
            'text-white': active && !disabled,
            'text-twMidDarkPurple': disabled
        }">
        <ng-content></ng-content>
    </div>
    <div>
        <div class="text font-semibold">
            {{ title }}
        </div>
        <div class="text-xxs">
            {{ subTitle }}
        </div>
    </div>
</div>
