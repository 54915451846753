<svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path
        opacity="0.3"
        d="M15 5.5C15 4.67157 14.3284 4 13.5 4C12.6716 4 12 4.67157 12 5.5V15.5C12 16.3284 12.6716 17 13.5 17C14.3284 17 15 16.3284 15 15.5V5.5Z" />
    <path
        opacity="0.3"
        d="M10.001 10.502C10.001 9.67353 9.3294 9.00195 8.50098 9.00195C7.67255 9.00195 7.00098 9.67353 7.00098 10.502V15.502C7.00098 16.3304 7.67255 17.002 8.50098 17.002C9.3294 17.002 10.001 16.3304 10.001 15.502V10.502Z" />
    <path
        d="M5 19H20C20.5523 19 21 19.4477 21 20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3C4.55228 3 5 3.44772 5 4V19Z" />
    <path
        opacity="0.3"
        d="M19.999 12.5C19.999 11.6716 19.3275 11 18.499 11C17.6706 11 16.999 11.6716 16.999 12.5V15.5C16.999 16.3284 17.6706 17 18.499 17C19.3275 17 19.999 16.3284 19.999 15.5V12.5Z" />
</svg>
