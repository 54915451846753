import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthService } from '@core/services/auth.service';

@Component({
    selector: 'app-root',
    template: ` <router-outlet /> `,
    imports: [RouterOutlet],
    standalone: true,
})
export class AppComponent {
    private readonly authService = inject(AuthService);

    constructor() {
        this.authService.checkIsLoggedIn();
    }
}
