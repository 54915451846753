<aside
    class="relative flex w-[487px] flex-col justify-around bg-twElectricPurple text-twTextInverse">
    <div class="pl-14 pt-[2vh]">
        <img
            ngSrc="assets/twinny-logos/twinny-logo-white.png"
            width="200"
            height="44"
            priority
            alt="Twinny logo" />
    </div>

    <div class="px-[84px]">
        <div class="pb-5 font-medium">Your setups lists</div>
        <div class="font-light leading-6">
            Get your account ready to send personalized automated campaigns to your customers
            through email/SMS
        </div>
    </div>
    <div class="flex flex-col gap-7 px-[84px] pb-[20vh]">
        <button [disabled]="igS.igNeedsReconnect()" (click)="goTo('ecommerce')">
            <app-sync-side-bar-btn
                title="Connect your E-commerce"
                subTitle="Choose platform and sync data"
                [active]="getSlug() === 'ecommerce'"
                [disabled]="igS.igNeedsReconnect()">
                <app-sync-ecommerce-icon />
            </app-sync-side-bar-btn>
        </button>

        <button [disabled]="igS.igNeedsReconnect()" (click)="goTo('social-media')">
            <app-sync-side-bar-btn
                title="Connect your Social media"
                subTitle="Sync data from social media"
                [active]="getSlug() === 'social-media'"
                [disabled]="igS.igNeedsReconnect()">
                <app-sync-social-media-icon />
            </app-sync-side-bar-btn>
        </button>

        <button [disabled]="igS.igNeedsReconnect()" (click)="goTo('analytics')">
            <app-sync-side-bar-btn
                title="Connect your Web traffic"
                subTitle="Sync your google analytics data"
                [active]="getSlug() === 'analytics'"
                [disabled]="igS.igNeedsReconnect()">
                <app-sync-analytics-icon />
            </app-sync-side-bar-btn>
        </button>
    </div>

    <div class="absolute bottom-3 left-[50%] right-[50%] flex justify-center whitespace-nowrap">
        <button class="flex w-fit flex-col font-semibold" (click)="goToDashboard()">
            <div>Go to {{ igS.igNeedsReconnect() ? 'Login' : 'Dashboard' }}</div>
            <div class="w-full border-b border-twTextInverse"></div>
        </button>
    </div>
</aside>
