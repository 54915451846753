import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { environment } from 'src/environments/environment';

export const privatePagesGuard: CanActivateFn = () => {
    const router = inject(Router);
    const authS = inject(AuthService);

    //if (environment.avoidAuth) return true;

    const isLogged = authS.isUserLogged();
    if (!isLogged) router.navigateByUrl('/auth/login');

    return isLogged;
};
