import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { InstagramService } from '@core/services/admin/instagram.service';
import { LogoutService } from '@core/services/logout.service';
import { SyncAnalyticsIconComponent } from '@features/sync/components/icons/sync-analytics-icon/sync-analytics-icon.component';
import { SyncEcommerceIconComponent } from '@features/sync/components/icons/sync-ecommerce-icon/sync-ecommerce-icon.component';
import { SyncSocialMediaIconComponent } from '@features/sync/components/icons/sync-social-media-icon/sync-social-media-icon.component';
import { environment } from 'src/environments/environment';
import { SyncSideBarBtnComponent } from '../sync-side-bar-btn/sync-side-bar-btn.component';

@Component({
    selector: 'app-sync-side-bar',
    standalone: true,
    imports: [
        CommonModule,
        SyncSideBarBtnComponent,
        SyncEcommerceIconComponent,
        SyncSocialMediaIconComponent,
        SyncAnalyticsIconComponent,
        NgOptimizedImage,
    ],
    templateUrl: './sync-side-bar.component.html',
    styles: [':host { display: flex;}'],
})
export class SyncSideBarComponent {
    private readonly router = inject(Router);
    private readonly logoutS = inject(LogoutService);
    igS = inject(InstagramService);

    getSlug() {
        let slug = this.router.url.split('/')[2];
        if (slug.includes('?')) slug = slug.split('?')[0];
        return slug;
    }
    goTo(slug: string) {
        this.router.navigate([`/sync/${slug}`]);
    }

    goToDashboard() {
        if (!this.igS.igNeedsReconnect()) this.router.navigate([environment.front.home]);
        else this.logout();
    }

    private logout() {
        this.logoutS.logout();
        this.router.navigate(['']);
    }
}
