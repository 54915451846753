import { Routes } from '@angular/router';
import { EcommerceGuard } from '@core/guards/sync/ecommerce.guard';

export const SYNC_ROUTES: Routes = [
    {
        path: 'ecommerce',
        children: [
            {
                path: '',
                title: 'Sync e-commerce',
                loadComponent: () =>
                    import(
                        '@features/sync/components/sync-platform-selection/sync-platform-selection.component'
                    ).then(m => m.SyncPlatformSelectionComponent),
            },
            {
                path: 'shopify',
                title: 'Sync Shopify',
                pathMatch: 'full',
                canActivate: [EcommerceGuard],
                loadComponent: () =>
                    import(
                        '@features/sync/components/ecommerce/sync-shopify/sync-shopify.component'
                    ).then(m => m.SyncShopifyComponent),
            },
            {
                path: 'magento',
                title: 'Sync Magento',
                pathMatch: 'full',
                canActivate: [EcommerceGuard],
                loadComponent: () =>
                    import(
                        '@features/sync/components/ecommerce/sync-magento/sync-magento.component'
                    ).then(m => m.SyncMagentoComponent),
            },
            {
                path: 'woo-commerce',
                title: 'Sync WooCommerce',
                pathMatch: 'full',
                canActivate: [EcommerceGuard],
                loadComponent: () =>
                    import(
                        '@features/sync/components/ecommerce/sync-woo-commerce/sync-woo-commerce.component'
                    ).then(m => m.SyncWooCommerceComponent),
            },
        ],
    },
    {
        path: 'social-media',
        children: [
            {
                path: '',
                title: 'Sync social media',
                loadComponent: () =>
                    import(
                        '@features/sync/components/sync-platform-selection/sync-platform-selection.component'
                    ).then(m => m.SyncPlatformSelectionComponent),
            },
            {
                path: 'instagram',
                title: 'Sync Instagram',
                loadComponent: () =>
                    import(
                        '@features/sync/components/social-media/sync-instagram/sync-instagram.component'
                    ).then(m => m.SyncInstagramComponent),
            },
        ],
    },
    {
        path: 'analytics',
        title: 'Sync analytics',
        loadComponent: () =>
            import(
                '@features/sync/components/sync-platform-selection/sync-platform-selection.component'
            ).then(m => m.SyncPlatformSelectionComponent),
    },
    { path: '**', redirectTo: 'ecommerce' },
];
