import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-connect-icons',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './connect-icons.component.html',
    styleUrls: [],
})
export class ConnectIconsComponent {}
