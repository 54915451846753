import { Routes } from '@angular/router';
import { authPagesGuard } from '@core/guards/auth/auth-pages.guard';
import { privatePagesGuard } from '@core/guards/auth/private-pages.guard';
import { AUTH_SLUGS } from './routes.types';

export const AUTH_ROUTES: Routes = [
    {
        path: AUTH_SLUGS.LOGIN,
        title: 'Login',
        canActivate: [authPagesGuard],
        loadComponent: () =>
            import('@features/auth/login/login.component').then(m => m.LoginComponent),
    },
    {
        path: AUTH_SLUGS.SIGNUP,
        title: 'Signup',
        canActivate: [authPagesGuard],
        loadComponent: () =>
            import('@features/auth/signup/signup.component').then(m => m.SignupComponent),
    },
    {
        path: AUTH_SLUGS.FORGOT_PASSWORD,
        title: 'Forgot password',
        canActivate: [authPagesGuard],
        loadComponent: () =>
            import('@features/auth/forgot-password/forgot-password.component').then(
                m => m.ForgotPasswordComponent
            ),
    },
    {
        path: AUTH_SLUGS.RESET_PASSWORD + '/:token',
        title: 'Reset password',
        pathMatch: 'full',
        loadComponent: () =>
            import('@features/auth/reset-password/reset-password.component').then(
                m => m.ResetPasswordComponent
            ),
    },
    {
        path: AUTH_SLUGS.SELECT_INDUSTRY,
        title: 'Select industry',
        canActivate: [privatePagesGuard],
        loadComponent: () =>
            import('@features/auth/select-industry/select-industry.component').then(
                m => m.SelectIndustryComponent
            ),
    },
    {
        path: AUTH_SLUGS.VERIFY,
        title: 'Verify',
        canActivate: [authPagesGuard],
        loadComponent: () =>
            import('@features/auth/verification-code/verification-code.component').then(
                m => m.VerificationCodeComponent
            ),
    },
    { path: '**', redirectTo: 'login' },
];
