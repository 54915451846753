import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-sync-side-bar-btn',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './sync-side-bar-btn.component.html',
    styles: [],
})
export class SyncSideBarBtnComponent {
    @Input() title = '';
    @Input() subTitle = '';
    @Input() active = false;
    @Input() disabled = false;
}
