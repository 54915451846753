import { Injectable, inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { EcommerceAccountService } from '@core/services/admin/ecommerce-account.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class EcommerceGuard {
    toastr = inject(ToastrService);
    router = inject(Router);
    ecommerceS = inject(EcommerceAccountService);

    async canActivate(): Promise<boolean | UrlTree> {
        const isEcommerceSyncd = this.ecommerceS.ecommerceAccount$.getValue()?.connected;

        if (isEcommerceSyncd) {
            this.toastr.error('You have an E-commerce connected');
            this.router.navigateByUrl('/sync/ecommerce');
            return false;
        } else {
            return true;
        }
    }
}
