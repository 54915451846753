<div
    #spacer
    [ngClass]="{
        hidden: isSideBarOpen()
    }"
    class="w-16 min-w-[68px]"></div>

<aside
    [ngClass]="{
        'w-52': isSideBarOpen(),
        'w-[68px] hover:w-52 absolute': !isSideBarOpen()
    }"
    class="group bottom-0 top-0 z-50 flex flex-col justify-between overflow-hidden border-r-[1px] bg-twComponentBackground text-twGrayDark transition-[width]"
    aria-label="Sidebar">
    <div class="flex flex-col">
        <div
            [ngClass]="{
                'ml-2': isSideBarOpen(),
                'group-hover:ml-2': !isSideBarOpen()
            }"
            class="ml-0 flex items-end gap-5 px-3 pb-20 pt-8 transition-[margin_left]">
            <img
                ngSrc="assets/twinny-logos/twinny-logo-only.png"
                class="min-w-[40px] max-w-[40px]"
                alt=""
                height="108"
                priority
                width="144" />
            <div
                [ngClass]="{
                    'opacity-100': isSideBarOpen(),
                    'group-hover:opacity-100': !isSideBarOpen()
                }"
                class="mb-[-4px] h-8 min-w-[100px] bg-[url('assets/twinny-logos/twinny-title-only.png')] bg-contain bg-center bg-no-repeat opacity-0 transition-[opacity]"></div>
        </div>

        <menu class="flex flex-col gap-3">
            <ul>
                <a
                    class="flex h-10 w-full items-center justify-between"
                    routerLinkActive="active"
                    [routerLink]="MAIN.DASHBOARD"
                    [routerLinkActiveOptions]="{ exact: true }">
                    <div class="flex items-center">
                        <div class="mx-5 flex min-w-[24px] items-center justify-center">
                            <app-dashboard-icon class="icon flex justify-center text-twGrayIcons" />
                        </div>
                        <span
                            [ngClass]="{
                                'opacity-100': isSideBarOpen(),
                                'group-hover:opacity-100': !isSideBarOpen()
                            }"
                            class="link-text whitespace-nowrap opacity-0 transition-[width_opacity]">
                            Dashboard
                        </span>
                    </div>
                    <div class="active-marker h-10 w-1 rounded-tl-[100%] bg-twElectricPurple"></div>
                </a>
            </ul>

            <ul>
                <a
                    class="flex h-10 w-full items-center justify-between"
                    routerLinkActive="active"
                    [routerLink]="MAIN.SMART_INBOX"
                    [routerLinkActiveOptions]="{ exact: true }">
                    <div class="flex items-center">
                        <div class="mx-5 flex min-w-[24px] items-center justify-center">
                            <app-inbox-icon
                                class="icon ml-[-2px] flex h-6 w-7 justify-center text-twGrayIcons" />
                        </div>
                        <span
                            [ngClass]="{
                                'opacity-100': isSideBarOpen(),
                                'group-hover:opacity-100': !isSideBarOpen()
                            }"
                            class="link-text whitespace-nowrap opacity-0 transition-[width_opacity]">
                            Inbox AI
                        </span>
                    </div>
                    <div class="active-marker h-10 w-1 rounded-tl-[100%] bg-twElectricPurple"></div>
                </a>
            </ul>

            <ul>
                <a
                    class="flex h-10 w-full items-center justify-between"
                    routerLinkActive="active"
                    [routerLink]="MAIN.PRODUCTS">
                    <div class="flex items-center">
                        <div class="mx-5 flex min-w-[24px] items-center justify-center">
                            <app-bag-icon
                                class="icon flex h-5 w-4 justify-center text-twGrayIcons" />
                        </div>
                        <span
                            [ngClass]="{
                                'opacity-100': isSideBarOpen(),
                                'group-hover:opacity-100': !isSideBarOpen()
                            }"
                            class="link-text whitespace-nowrap opacity-0 transition-[width_opacity]">
                            Products
                        </span>
                    </div>
                    <div class="active-marker h-10 w-1 rounded-tl-[100%] bg-twElectricPurple"></div>
                </a>
            </ul>

            <ul>
                <a
                    class="flex h-10 w-full items-center justify-between"
                    routerLinkActive="active"
                    [routerLink]="MAIN.AUDIENCE">
                    <div class="flex items-center">
                        <div class="mx-5 flex min-w-[24px] items-center justify-center">
                            <app-people-icon
                                class="icon flex size-6 justify-center text-twGrayIcons" />
                        </div>
                        <span
                            [ngClass]="{
                                'opacity-100': isSideBarOpen(),
                                'group-hover:opacity-100': !isSideBarOpen()
                            }"
                            class="link-text whitespace-nowrap opacity-0 transition-[width_opacity]">
                            Audience
                        </span>
                    </div>
                    <div class="active-marker h-10 w-1 rounded-tl-[100%] bg-twElectricPurple"></div>
                </a>
            </ul>

            <ul>
                <a
                    class="flex h-10 w-full items-center justify-between"
                    routerLinkActive="active"
                    [routerLink]="MAIN.CAMPAIGNS">
                    <div class="flex items-center">
                        <div class="mx-5 flex min-w-[24px] items-center justify-center">
                            <app-loud-speaker-icon
                                class="icon flex size-6 justify-center text-twGrayIcons" />
                        </div>
                        <span
                            [ngClass]="{
                                'opacity-100': isSideBarOpen(),
                                'group-hover:opacity-100': !isSideBarOpen()
                            }"
                            class="link-text whitespace-nowrap opacity-0 transition-[width_opacity]">
                            Campaigns
                        </span>
                    </div>
                    <div class="active-marker h-10 w-1 rounded-tl-[100%] bg-twElectricPurple"></div>
                </a>
            </ul>

            <ul>
                <a
                    class="flex h-10 w-full items-center justify-between"
                    routerLinkActive="active"
                    [routerLink]="MAIN.AUTOMATION"
                    [routerLinkActiveOptions]="{ exact: true }">
                    <div class="flex items-center">
                        <div class="mx-5 flex min-w-[24px] items-center justify-center">
                            <app-automate-icon
                                class="icon flex size-6 justify-center text-twGrayIcons" />
                        </div>
                        <span
                            [ngClass]="{
                                'opacity-100': isSideBarOpen(),
                                'group-hover:opacity-100': !isSideBarOpen()
                            }"
                            class="link-text whitespace-nowrap opacity-0 transition-[width_opacity]">
                            Automation
                        </span>
                    </div>
                    <div class="active-marker h-10 w-1 rounded-tl-[100%] bg-twElectricPurple"></div>
                </a>
            </ul>

            <ul>
                <a
                    class="flex h-10 w-full items-center justify-between"
                    routerLinkActive="active"
                    [routerLink]="MAIN.PAYMENT">
                    <div class="flex items-center">
                        <div class="mx-5 flex min-w-[24px] items-center justify-center">
                            <app-credit-card-icon
                                class="icon flex size-6 justify-center text-twGrayIcons" />
                        </div>
                        <span
                            [ngClass]="{
                                'opacity-100': isSideBarOpen(),
                                'group-hover:opacity-100': !isSideBarOpen()
                            }"
                            class="link-text whitespace-nowrap opacity-0 transition-[width_opacity]">
                            Payment
                        </span>
                    </div>
                    <div class="active-marker h-10 w-1 rounded-tl-[100%] bg-twElectricPurple"></div>
                </a>
            </ul>

            <ul>
                <div class="flex h-10 w-full items-center justify-between">
                    <div class="flex w-full items-center">
                        <div class="mx-5 flex min-w-[24px] items-center justify-center">
                            <app-analytics-icon
                                [ngClass]="{
                                    'text-twElectricPurple': isAnalytics(),
                                    'text-twGrayIcons': !isAnalytics()
                                }"
                                class="icon flex size-6 justify-center" />
                        </div>
                        <span
                            [ngClass]="{
                                'opacity-100': isSideBarOpen(),
                                'group-hover:opacity-100': !isSideBarOpen()
                            }"
                            class="link-text whitespace-nowrap opacity-0 transition-[width_opacity]">
                            Analytics
                        </span>
                        <div
                            [ngClass]="{
                                'opacity-100': isSideBarOpen(),
                                'group-hover:opacity-100': !isSideBarOpen()
                            }"
                            class="ml-auto mr-6 rotate-90 text-xxs opacity-0 transition-[width_opacity]">
                            >
                        </div>
                    </div>
                    <div
                        class="active-marker h-full w-1 rounded-tl-[100%] bg-twElectricPurple"></div>
                </div>

                <div
                    [ngClass]="{
                        'opacity-100': isSideBarOpen(),
                        'group-hover:opacity-100': !isSideBarOpen()
                    }"
                    class="flex flex-col text-[13px] text-twGrayDark opacity-0 transition-[width_opacity]">
                    <a
                        class="flex h-6 w-full items-center justify-between"
                        routerLinkActive="active"
                        [routerLink]="MAIN.LISTENING"
                        [routerLinkActiveOptions]="{ exact: true }">
                        <div class="flex h-full items-center">
                            <div
                                class="mx-5 flex min-h-full min-w-[24px] items-center justify-center text-twGrayLight">
                                <svg class="icon h-6 w-[1px]" fill="currentColor">
                                    <rect height="24" width="1" />
                                </svg>
                            </div>
                            <span
                                [ngClass]="{
                                    'opacity-100': isSideBarOpen(),
                                    'group-hover:opacity-100': !isSideBarOpen()
                                }"
                                class="link-text whitespace-nowrap pl-3 opacity-0 transition-[width_opacity]">
                                Social listening
                            </span>
                        </div>
                        <div
                            class="active-marker h-full w-1 rounded-tl-[100%] bg-twElectricPurple"></div>
                    </a>
                    <a
                        class="flex h-6 w-full items-center justify-between"
                        routerLinkActive="active"
                        [routerLink]="MAIN.USERS"
                        [routerLinkActiveOptions]="{ exact: true }">
                        <div class="flex h-full items-center">
                            <div
                                class="mx-5 flex min-h-full min-w-[24px] items-center justify-center text-twGrayLight">
                                <svg class="icon h-6 w-[1px]" fill="currentColor">
                                    <rect height="24" width="1" />
                                </svg>
                            </div>
                            <span
                                [ngClass]="{
                                    'opacity-100': isSideBarOpen(),
                                    'group-hover:opacity-100': !isSideBarOpen()
                                }"
                                class="link-text whitespace-nowrap pl-3 opacity-0 transition-[width_opacity]">
                                Social users
                            </span>
                        </div>
                        <div
                            class="active-marker h-full w-1 rounded-tl-[100%] bg-twElectricPurple"></div>
                    </a>
                </div>
            </ul>
        </menu>
    </div>

    <div class="relative flex max-w-[100%] items-center pb-9">
        <button class="flex max-w-[100%] items-center gap-4" (click)="handleAvatarClick($event)">
            <app-avatar class="ml-4" [name]="name()" [src]="profileImg()" />
            <div
                [ngClass]="{
                    'opacity-100': isSideBarOpen(),
                    'group-hover:opacity-100': !isSideBarOpen()
                }"
                class="min-w-[136px] opacity-0 transition-[width_opacity]">
                <div
                    class="overflow-hidden whitespace-normal break-all text-start text-twTextPrimary">
                    {{ (accountS.account()?.account_name | titlecase) || '' }}
                </div>
            </div>
        </button>
    </div>
</aside>

<app-avatar-menu
    class="absolute bottom-0 left-0 z-[51] mt-[40px]"
    [isMenuOpen]="isMenuOpen"
    (toggleMenu)="handleAvatarClick($event)" />
