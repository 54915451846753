import { HttpInterceptorFn } from '@angular/common/http';
import { catchError } from 'rxjs';

export const errorHandlerInterceptor: HttpInterceptorFn = (req, next) => {
    return next(req);
    // .pipe(
    //     catchError(err => {
    //         console.log('inter', err);
    //         if (err.status === 413) {
    //             console.log('TOO LARGE');
    //         }
    //         throw err;
    //     })
    // );
};
