<svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path
        d="M7.50033 18.3332H12.5003C16.667 18.3332 18.3337 16.6665 18.3337 12.4998V7.49984C18.3337 3.33317 16.667 1.6665 12.5003 1.6665H7.50033C3.33366 1.6665 1.66699 3.33317 1.66699 7.49984V12.4998C1.66699 16.6665 3.33366 18.3332 7.50033 18.3332Z"
        stroke-linecap="round"
        stroke-linejoin="round" />
    <path
        d="M6.45801 9.99993L8.81634 12.3583L13.5413 7.6416"
        stroke-linecap="round"
        stroke-linejoin="round" />
</svg>
