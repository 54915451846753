import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SyncSideBarComponent } from './layout/sync-side-bar/sync-side-bar.component';

@Component({
    selector: 'app-sync',
    standalone: true,
    imports: [CommonModule, RouterModule, NgOptimizedImage, SyncSideBarComponent],
    templateUrl: './sync.component.html',
    styles: [':host { display: flex; height: 100%; width: 100%; }'],
})
export class SyncComponent {}
