import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-square-checkmark',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './square-checkmark.component.html',
    styles: [],
})
export class SquareCheckmarkComponent {}
