import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { InstagramService } from '@core/services/admin/instagram.service';

export const igReconnectGuard: CanActivateFn = (route, state) => {
    const router = inject(Router);
    const igS = inject(InstagramService);

    const isPageIgSync = state.url.includes('sync/social-media/instagram');
    if (igS.igNeedsReconnect() && !isPageIgSync)
        router.navigateByUrl('/sync/social-media/instagram');

    return true;
};
