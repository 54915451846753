import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { LogoutService } from '@core/services/logout.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';
const getSlug=() =>{
        const url = window.location.href;
        const urlArr = url.split('/');
        return urlArr[urlArr.length - 1];
    }
export const authInterceptor: HttpInterceptorFn = (req, next) => {
    const router = inject(Router);
    const route = inject(ActivatedRoute);
    const toastr = inject(ToastrService);
    const cookie = inject(CookieService);
    const authS = inject(AuthService);
    const logoutS = inject(LogoutService);

        const token = authS.token() || cookie.get('auth.token');
        let request;

        if (token) {
            request = req.clone({
                withCredentials: true,
                setHeaders: {
                    authorization: `Bearer ${token}`,
                },
            });
        } else {
            request = req.clone({ withCredentials: true });
        }

        return next(request).pipe(
            catchError((err: HttpErrorResponse) => {
                const network = 'Please check your internet connection';
                const slug = getSlug();

                switch (err.status) {
                    case 0:
                        toastr.error(network);
                        break;
                    case 401:
                        if (slug !== 'login') {
                            toastr.error('Please log in again', 'Session expired');
                        }
                        logoutS.logout();
                        break;
                    // case 403:
                    //     if (environment.mode !== 'dev') {
                    //         const url = `${APP_SLUGS.MAIN}/${MAIN_SLUGS.ACCOUNT}/${SETTINGS_SLUGS.settings}/${SETTINGS_SLUGS.billing}`;
                    //         router.navigateByUrl(url);
                    //     }
                    //     break;
                }

                return throwError(() => err);
            })
        );
    }

