import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-inbox-icon',
    standalone: true,
    imports: [],

    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `<svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" fill="none" />
        <path
            d="M19.9915 13.6486C19.9962 13.6107 19.9962 13.5721 19.9915 13.5334C19.9815 13.4909 19.9683 13.4499 19.9505 13.4098L17.1637 8.70115C16.8932 8.32392 16.4572 8.09972 15.9926 8.09972H14.4341C14.0924 8.09972 13.8157 8.37646 13.8157 8.71815C13.8157 9.05907 14.0924 9.33659 14.4341 9.33659H15.9926C16.0397 9.33659 16.0853 9.35127 16.1248 9.37756L18.3017 13.0472H16.652C16.0305 13.0472 15.4785 13.4461 15.2837 14.0367L14.8384 15.381V15.3802C14.8075 15.4637 14.7287 15.5194 14.6405 15.5209H9.25552C9.16739 15.5194 9.08854 15.4637 9.05762 15.3802L8.61235 14.0359V14.0367C8.41754 13.4461 7.8656 13.0472 7.24406 13.0472H5.59517L7.7388 9.41931V9.41854C7.77823 9.36752 7.83853 9.33737 7.90346 9.3366H9.48665C9.82834 9.3366 10.1051 9.05909 10.1051 8.71817C10.1051 8.37648 9.82834 8.09973 9.48665 8.09973H7.9282C7.4551 8.09355 7.00906 8.31774 6.7323 8.70117L4.00348 13.3356C3.99961 13.3765 3.99961 13.4183 4.00348 13.4592C3.99884 13.4979 3.99884 13.5365 4.00348 13.5744V13.6154V18.5636C3.98879 19.3406 4.58789 19.9907 5.36403 20.0394H18.5567C19.3522 20.0347 19.9953 19.3916 20 18.5961V13.6486L19.9915 13.6486Z"
            fill="currentColor" />
        <path
            d="M9.87345 12.4363L11.5231 14.086C11.5803 14.1417 11.6468 14.1865 11.721 14.2174C11.8733 14.2839 12.0464 14.2839 12.1988 14.2174C12.273 14.1865 12.3394 14.1417 12.3966 14.086L14.0463 12.4363C14.2728 12.1928 14.2666 11.8133 14.0308 11.5775C13.7951 11.3425 13.4155 11.3355 13.172 11.5628L12.5783 12.1565V4.57839C12.5783 4.2367 12.3016 3.95996 11.9599 3.95996C11.6182 3.95996 11.3414 4.2367 11.3414 4.57839V12.1565L10.7478 11.5628C10.5042 11.3356 10.1247 11.3425 9.88892 11.5775C9.65314 11.8133 9.64694 12.1929 9.87345 12.4363Z"
            fill="currentColor" />
    </svg> `,
})
export class InboxIconComponent {}
