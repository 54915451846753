import { FacebookLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { currencyProvider } from '@core/currency/currency.token';
import { authInterceptor } from '@core/interceptors/auth.interceptor';
import { errorHandlerInterceptor } from '@core/interceptors/error-handler.interceptor';
import { environment } from '@environments/environment';
import { APP_ROUTES } from '@routes/app.routes';
import { SnakeToPipe } from '@shared/pipes/snake-to.pipe';
import { CustomizedNgxToastComponent } from '@shared/ui/customized-ngx-toast/customized-ngx-toast.component';
import { CookieService } from 'ngx-cookie-service';
import { provideToastr } from 'ngx-toastr';

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(APP_ROUTES, withComponentInputBinding()),
        provideHttpClient(withInterceptors([errorHandlerInterceptor, authInterceptor])),
        provideNativeDateAdapter(),
        provideToastr({
            toastComponent: CustomizedNgxToastComponent,
            autoDismiss: true,
            preventDuplicates: true,
        }),
        SnakeToPipe,
        CookieService,
        currencyProvider,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(environment.facebookClientId),
                    },
                ],
                onError: err => {
                    console.error(err);
                },
            } as SocialAuthServiceConfig,
        },
    ],
};
