import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { AccountService } from '@core/services/admin/account.service';
import { SubscriptionService } from '@core/services/admin/subscription.service';
import { StickyBarWarningComponent } from '@shared/ui/sticky-bar-warning/sticky-bar-warning.component';
import { SidebarComponent } from '../sidebar/sidebar.component';

@Component({
    selector: 'app-main-content',
    standalone: true,
    imports: [CommonModule, RouterModule, SidebarComponent, StickyBarWarningComponent],
    templateUrl: './main-content.component.html',
    styles: [':host {display: flex; flex-direction: column; flex: 1 1 0; overflow: hidden}'],
})
export class MainContentComponent implements OnInit {
    readonly accS = inject(AccountService);
    readonly subS = inject(SubscriptionService);
    private readonly router = inject(Router);
    isInDashboard = signal(false);

    ngOnInit() {
        this.isInDashboard.set(this.router.url.includes('dashboard'));
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.isInDashboard.set(event.url.includes('dashboard'));
            }
        });
    }
}
