<div
    *ngIf="isMenuOpen"
    @openClose
    class="absolute bottom-7 left-20 min-w-[207px] rounded-lg bg-white p-3 shadow-popMenu"
    tabindex="0"
    (click)="$event.stopPropagation()"
    (keyup.enter)="$event.stopPropagation()">
    <div>
        <div class="relative flex items-center gap-2 pb-[10px] text-left">
            <app-avatar class="ml-4 h-[40px] w-[40px]" [name]="name()" [src]="profileImg()" />
            <div class="mt-1">
                <p class="pt-[4px] font-poppins text-xs font-medium text-black">
                    {{ name() }}
                </p>
                <p class="mb-[3px] text-xs text-gray-500">{{ email() }}</p>
            </div>
        </div>

        <div class="grid grid-cols-[20px_1fr] grid-rows-3 items-center gap-x-2 gap-y-1">
            <button
                class="group col-span-2 grid grid-cols-subgrid rounded-lg py-1 hover:bg-twLightPurple"
                (click)="handleOptionClick($event, '/sync/ecommerce')">
                <app-connect-icons
                    class="ml-3 stroke-twGrayIcons pl-[2px] group-hover:stroke-twElectricPurple" />
                <span class="ml-2 text-start text-xs text-twGrayIcons group-hover:text-black">
                    Connect
                </span>
            </button>

            <button
                class="group col-span-2 grid grid-cols-subgrid rounded-lg py-1 hover:bg-twLightPurple"
                (click)="handleOptionClick($event, '/main/account')">
                <app-gear-icon
                    class="ml-3 h-5 w-5 stroke-twGrayIcons group-hover:stroke-twElectricPurple" />
                <span
                    class="ml-2 self-center text-start text-xs text-twGrayIcons group-hover:text-black">
                    Settings
                </span>
            </button>

            <button
                class="group col-span-2 grid grid-cols-subgrid rounded-lg py-1 hover:bg-twLightPurple"
                (click)="logOut()">
                <app-logout-icons
                    class="ml-3 stroke-twGrayIcons group-hover:stroke-twElectricPurple" />
                <span class="ml-2 text-start text-xs text-twGrayIcons group-hover:text-black">
                    Log out
                </span>
            </button>
        </div>
    </div>
</div>
