<svg fill="none" height="100%" viewbox="0 0 20 20" width="100%" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6685_11702)">
        <path
            d="M15.9501 10.7833C15.9834 10.5333 16.0001 10.275 16.0001 10C16.0001 9.73333 15.9834 9.46667 15.9417 9.21667L17.6334 7.9C17.7834 7.78333 17.8251 7.55833 17.7334 7.39167L16.1334 4.625C16.0334 4.44167 15.8251 4.38333 15.6417 4.44167L13.6501 5.24167C13.2334 4.925 12.7917 4.65833 12.3001 4.45833L12.0001 2.34167C11.9667 2.14167 11.8001 2 11.6001 2H8.40005C8.20005 2 8.04172 2.14167 8.00838 2.34167L7.70838 4.45833C7.21672 4.65833 6.76672 4.93333 6.35838 5.24167L4.36672 4.44167C4.18338 4.375 3.97505 4.44167 3.87505 4.625L2.28338 7.39167C2.18338 7.56667 2.21672 7.78333 2.38338 7.9L4.07505 9.21667C4.03338 9.46667 4.00005 9.74167 4.00005 10C4.00005 10.2583 4.01672 10.5333 4.05838 10.7833L2.36672 12.1C2.21672 12.2167 2.17505 12.4417 2.26672 12.6083L3.86672 15.375C3.96672 15.5583 4.17505 15.6167 4.35838 15.5583L6.35005 14.7583C6.76672 15.075 7.20838 15.3417 7.70005 15.5417L8.00005 17.6583C8.04172 17.8583 8.20005 18 8.40005 18H11.6001C11.8001 18 11.9667 17.8583 11.9917 17.6583L12.2917 15.5417C12.7834 15.3417 13.2334 15.075 13.6417 14.7583L15.6334 15.5583C15.8167 15.625 16.0251 15.5583 16.1251 15.375L17.7251 12.6083C17.8251 12.425 17.7834 12.2167 17.6251 12.1L15.9501 10.7833ZM10.0001 13C8.35005 13 7.00005 11.65 7.00005 10C7.00005 8.35 8.35005 7 10.0001 7C11.6501 7 13.0001 8.35 13.0001 10C13.0001 11.65 11.6501 13 10.0001 13Z"
            fill="#ADADB6"></path>
    </g>
    <defs>
        <clippath id="clip0_6685_11702">
            <rect fill="white" height="20" width="20"></rect>
        </clippath>
    </defs>
</svg>
