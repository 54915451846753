<div class="flex w-full flex-1 overflow-y-hidden">
    <app-sidebar class="relative flex" [isSideBarOpen]="isInDashboard()"></app-sidebar>
    <main
        class="relative flex h-full w-full flex-col overflow-auto bg-twMainBackground text-twTextPrimary">
        <app-sticky-bar-warning
            *ngIf="subS.showStatusWarning()"
            class="absolute left-1/4 right-1/4 top-0 min-w-fit whitespace-nowrap"
            [sub]="accS.account()?.subscription" />

        <div class="flex-grow">
            <router-outlet />
        </div>
    </main>
</div>
