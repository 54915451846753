import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { environment } from 'src/environments/environment';

export const authPagesGuard: CanActivateFn = (route, state) => {
    const router = inject(Router);
    const authS = inject(AuthService);

    const isLoggedIn = authS.isUserLogged();
    if (isLoggedIn) {
        router.navigateByUrl(environment.front.home);
        return false;
    }
    return true;
};
