import { Routes } from '@angular/router';
import { AUTOMATION_SLUGS, FLOWS_SLUGS, PRESET_MESSAGES_SLUGS } from './routes.types';

export const FLOWS_ROUTES: Routes = [
    {
        path: FLOWS_SLUGS.table,
        title: 'Flows',
        loadComponent: () =>
            import('@features/automation/flows/flow-table/flow-table.component').then(
                m => m.FlowTableComponent
            ),
    },
    {
        path: FLOWS_SLUGS.editor,
        title: 'Flow Editor',
        loadComponent: () =>
            import('@features/automation/flows/flow-editor/flow-editor.component').then(
                m => m.FlowEditorComponent
            ),
    },
    {
        path: FLOWS_SLUGS.init,
        title: 'Flow Init',
        loadComponent: () =>
            import('@features/automation/flows/flow-initialize/flow-initialize.component').then(
                m => m.FlowInitializeComponent
            ),
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: FLOWS_SLUGS.table,
    },
] as const;

export const PRESET_MESSAGES_ROUTES: Routes = [
    {
        path: PRESET_MESSAGES_SLUGS.table,
        title: 'Preset Messages',
        loadComponent: () =>
            import(
                '@features/automation/preset-messages/preset-messages-table/preset-messages-table.component'
            ).then(m => m.PresetMessagesTableComponent),
    },
    {
        path: PRESET_MESSAGES_SLUGS.form,
        title: 'Preset Message Form',
        loadComponent: () =>
            import(
                '@features/automation/preset-messages/preset-messages-form/preset-messages-form.component'
            ).then(m => m.PresetMessagesFormComponent),
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: FLOWS_SLUGS.table,
    },
] as const;

export const AUTOMATION_ROUTES: Routes = [
    {
        path: AUTOMATION_SLUGS.flows,
        children: FLOWS_ROUTES,
    },
    {
        path: AUTOMATION_SLUGS.presetMessages,
        children: PRESET_MESSAGES_ROUTES,
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: AUTOMATION_SLUGS.flows,
    },
    {
        path: '**',
        redirectTo: AUTOMATION_SLUGS.flows,
    },
] as const;
